import { Container, Stack, Title } from "@mantine/core";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_auth/settings")({
  component: SettingsPage,
});

function SettingsPage() {
  return (
    <Container my="xl" size="md">
      <Stack>
        <Title order={1}>Settings</Title>
      </Stack>
    </Container>
  );
}
