import { createFileRoute, Outlet } from "@tanstack/react-router";
import { ActionIcon, Affix, Paper, Stack } from "@mantine/core";
import { Link } from "@tanstack/react-router";
import { IconX } from "@tabler/icons-react";

export const Route = createFileRoute("/_auth/maps/default/_modal")({
  component: MapsMapIdModal,
});

function MapsMapIdModal() {
  return (
    <Affix position={{ top: 5, left: 90 }}>
      <Paper shadow="xl" p="xl" w={400}>
        <Stack style={{ position: "relative" }}>
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <ActionIcon component={Link} to="/maps/default" variant="subtle">
              <IconX />
            </ActionIcon>
          </div>

          <Outlet />
        </Stack>
      </Paper>
    </Affix>
  );
}
