import { createFileRoute } from "@tanstack/react-router";
import { queryClient } from "~/app";
import {
  useSitesServiceFindOneSiteKey,
  useSitesServiceUpdateSite,
} from "~/api/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import SiteForm from "~/components/forms/site";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import { SitesService } from "~/api/requests";

const queryOptions = (id: string) => ({
  queryKey: [useSitesServiceFindOneSiteKey, id],
  queryFn: () => SitesService.findOneSite({ id }),
});

export const Route = createFileRoute("/_auth/sites/$siteId/")({
  component: SitePage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: "Edit Site",
  }),
  loader: ({ params }) =>
    queryClient.ensureQueryData(queryOptions(params.siteId)),
});

function SitePage() {
  const { siteId } = Route.useParams();

  const { data } = useSuspenseQuery(queryOptions(siteId));

  return (
    <SiteForm
      defaultValues={data}
      mutationFn={useSitesServiceUpdateSite}
      getVars={(requestBody) => ({ id: siteId, requestBody })}
      successMessage="Updated site"
      invalidateQueries={() =>
        queryClient.invalidateQueries(queryOptions(siteId))
      }
      cancelUrl="/sites"
    />
  );
}
