// src/user.ts
import z from "zod";
var userSchema = z.object({
  id: z.string(),
  email: z.string().email(),
  password: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phone: z.string(),
  active: z.boolean(),
  address: z.string(),
  addressLatitude: z.number(),
  addressLongitude: z.number(),
  createdAt: z.date()
});
var userInputSchema = userSchema.omit({ id: true, createdAt: true });

// src/route.ts
import z2 from "zod";
var routeSchema = z2.object({
  id: z2.string(),
  name: z2.string().min(1),
  distance: z2.number(),
  duration: z2.number(),
  returnDistance: z2.number(),
  returnDuration: z2.number(),
  assignee: z2.object({
    id: z2.string().nullable(),
    firstName: z2.string(),
    lastName: z2.string(),
    email: z2.string().email(),
    address: z2.string(),
    addressLatitude: z2.number(),
    addressLongitude: z2.number()
  }).nullable(),
  loop: z2.boolean(),
  stops: z2.array(
    z2.object({
      id: z2.string(),
      siteId: z2.string().nullable(),
      label: z2.string(),
      longitude: z2.number(),
      latitude: z2.number(),
      distance: z2.number(),
      duration: z2.number()
    })
  ).min(2)
});

// src/asset.ts
import z3 from "zod";
var assetOutputSchema = z3.object({
  id: z3.string(),
  name: z3.string(),
  ip: z3.string().ip(),
  port: z3.number().int().positive(),
  password: z3.string(),
  active: z3.boolean(),
  siteId: z3.string(),
  createdAt: z3.date()
});
var assetInputSchema = assetOutputSchema.omit({
  id: true,
  createdAt: true
});

// src/site.ts
import z4 from "zod";
var siteOutputSchema = z4.object({
  id: z4.string(),
  code: z4.string(),
  name: z4.string(),
  longitude: z4.number(),
  latitude: z4.number(),
  createdAt: z4.date()
});
var siteInputSchema = siteOutputSchema.omit({
  id: true,
  createdAt: true
});

// src/pagination.ts
import { z as z5 } from "zod";
var paginationSchema = z5.object({
  page: z5.number().int().nonnegative().catch(1),
  recordsPerPage: z5.number().int().nonnegative().catch(100)
});
var paginationOutputSchema = paginationSchema.extend({
  totalRecords: z5.number().int().nonnegative()
});

// src/map.ts
import z6 from "zod";
var mapOutputSchema = z6.object({
  id: z6.string(),
  name: z6.string(),
  styleLight: z6.string(),
  styleDark: z6.string(),
  createdAt: z6.date()
});
var mapInputSchema = mapOutputSchema.omit({
  id: true,
  createdAt: true
});
export {
  assetInputSchema,
  assetOutputSchema,
  mapInputSchema,
  mapOutputSchema,
  paginationOutputSchema,
  paginationSchema,
  routeSchema,
  siteInputSchema,
  siteOutputSchema,
  userInputSchema,
  userSchema
};
