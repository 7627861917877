// generated with @7nohe/openapi-react-query-codegen@1.4.1 

import { UseQueryResult } from "@tanstack/react-query";
import { AssetsService, AuthService, MapsService, RoutesService, SitesService, UsersService } from "../requests/services.gen";
export type AuthServiceGoogleDefaultResponse = Awaited<ReturnType<typeof AuthService.google>>;
export type AuthServiceGoogleQueryResult<TData = AuthServiceGoogleDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAuthServiceGoogleKey = "AuthServiceGoogle";
export const UseAuthServiceGoogleKeyFn = (queryKey?: Array<unknown>) => [useAuthServiceGoogleKey, ...(queryKey ?? [])];
export type AuthServiceAuthControllerGoogleCallbackDefaultResponse = Awaited<ReturnType<typeof AuthService.authControllerGoogleCallback>>;
export type AuthServiceAuthControllerGoogleCallbackQueryResult<TData = AuthServiceAuthControllerGoogleCallbackDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAuthServiceAuthControllerGoogleCallbackKey = "AuthServiceAuthControllerGoogleCallback";
export const UseAuthServiceAuthControllerGoogleCallbackKeyFn = (queryKey?: Array<unknown>) => [useAuthServiceAuthControllerGoogleCallbackKey, ...(queryKey ?? [])];
export type AuthServiceMicrosoftDefaultResponse = Awaited<ReturnType<typeof AuthService.microsoft>>;
export type AuthServiceMicrosoftQueryResult<TData = AuthServiceMicrosoftDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAuthServiceMicrosoftKey = "AuthServiceMicrosoft";
export const UseAuthServiceMicrosoftKeyFn = (queryKey?: Array<unknown>) => [useAuthServiceMicrosoftKey, ...(queryKey ?? [])];
export type AuthServiceAuthControllerMicrosoftCallbackDefaultResponse = Awaited<ReturnType<typeof AuthService.authControllerMicrosoftCallback>>;
export type AuthServiceAuthControllerMicrosoftCallbackQueryResult<TData = AuthServiceAuthControllerMicrosoftCallbackDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAuthServiceAuthControllerMicrosoftCallbackKey = "AuthServiceAuthControllerMicrosoftCallback";
export const UseAuthServiceAuthControllerMicrosoftCallbackKeyFn = (queryKey?: Array<unknown>) => [useAuthServiceAuthControllerMicrosoftCallbackKey, ...(queryKey ?? [])];
export type UsersServiceFindManyUsersDefaultResponse = Awaited<ReturnType<typeof UsersService.findManyUsers>>;
export type UsersServiceFindManyUsersQueryResult<TData = UsersServiceFindManyUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceFindManyUsersKey = "UsersServiceFindManyUsers";
export const UseUsersServiceFindManyUsersKeyFn = ({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: Array<unknown>) => [useUsersServiceFindManyUsersKey, ...(queryKey ?? [{ page, recordsPerPage }])];
export type UsersServiceSearchUsersDefaultResponse = Awaited<ReturnType<typeof UsersService.searchUsers>>;
export type UsersServiceSearchUsersQueryResult<TData = UsersServiceSearchUsersDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceSearchUsersKey = "UsersServiceSearchUsers";
export const UseUsersServiceSearchUsersKeyFn = ({ email, firstName, lastName }: {
  email?: string;
  firstName?: string;
  lastName?: string;
} = {}, queryKey?: Array<unknown>) => [useUsersServiceSearchUsersKey, ...(queryKey ?? [{ email, firstName, lastName }])];
export type UsersServiceFindMeDefaultResponse = Awaited<ReturnType<typeof UsersService.findMe>>;
export type UsersServiceFindMeQueryResult<TData = UsersServiceFindMeDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceFindMeKey = "UsersServiceFindMe";
export const UseUsersServiceFindMeKeyFn = (queryKey?: Array<unknown>) => [useUsersServiceFindMeKey, ...(queryKey ?? [])];
export type UsersServiceFindOneUserDefaultResponse = Awaited<ReturnType<typeof UsersService.findOneUser>>;
export type UsersServiceFindOneUserQueryResult<TData = UsersServiceFindOneUserDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useUsersServiceFindOneUserKey = "UsersServiceFindOneUser";
export const UseUsersServiceFindOneUserKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useUsersServiceFindOneUserKey, ...(queryKey ?? [{ id }])];
export type SitesServiceFindManySitesDefaultResponse = Awaited<ReturnType<typeof SitesService.findManySites>>;
export type SitesServiceFindManySitesQueryResult<TData = SitesServiceFindManySitesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSitesServiceFindManySitesKey = "SitesServiceFindManySites";
export const UseSitesServiceFindManySitesKeyFn = ({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: Array<unknown>) => [useSitesServiceFindManySitesKey, ...(queryKey ?? [{ page, recordsPerPage }])];
export type SitesServiceFindManySitesGeojsonDefaultResponse = Awaited<ReturnType<typeof SitesService.findManySitesGeojson>>;
export type SitesServiceFindManySitesGeojsonQueryResult<TData = SitesServiceFindManySitesGeojsonDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSitesServiceFindManySitesGeojsonKey = "SitesServiceFindManySitesGeojson";
export const UseSitesServiceFindManySitesGeojsonKeyFn = (queryKey?: Array<unknown>) => [useSitesServiceFindManySitesGeojsonKey, ...(queryKey ?? [])];
export type SitesServiceSearchSitesDefaultResponse = Awaited<ReturnType<typeof SitesService.searchSites>>;
export type SitesServiceSearchSitesQueryResult<TData = SitesServiceSearchSitesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSitesServiceSearchSitesKey = "SitesServiceSearchSites";
export const UseSitesServiceSearchSitesKeyFn = ({ code }: {
  code: unknown;
}, queryKey?: Array<unknown>) => [useSitesServiceSearchSitesKey, ...(queryKey ?? [{ code }])];
export type SitesServiceFindOneSiteDefaultResponse = Awaited<ReturnType<typeof SitesService.findOneSite>>;
export type SitesServiceFindOneSiteQueryResult<TData = SitesServiceFindOneSiteDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useSitesServiceFindOneSiteKey = "SitesServiceFindOneSite";
export const UseSitesServiceFindOneSiteKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useSitesServiceFindOneSiteKey, ...(queryKey ?? [{ id }])];
export type AssetsServiceFindManyAssetsDefaultResponse = Awaited<ReturnType<typeof AssetsService.findManyAssets>>;
export type AssetsServiceFindManyAssetsQueryResult<TData = AssetsServiceFindManyAssetsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAssetsServiceFindManyAssetsKey = "AssetsServiceFindManyAssets";
export const UseAssetsServiceFindManyAssetsKeyFn = ({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: Array<unknown>) => [useAssetsServiceFindManyAssetsKey, ...(queryKey ?? [{ page, recordsPerPage }])];
export type AssetsServiceSearchAssetsDefaultResponse = Awaited<ReturnType<typeof AssetsService.searchAssets>>;
export type AssetsServiceSearchAssetsQueryResult<TData = AssetsServiceSearchAssetsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAssetsServiceSearchAssetsKey = "AssetsServiceSearchAssets";
export const UseAssetsServiceSearchAssetsKeyFn = ({ name }: {
  name: unknown;
}, queryKey?: Array<unknown>) => [useAssetsServiceSearchAssetsKey, ...(queryKey ?? [{ name }])];
export type AssetsServiceFindOneAssetDefaultResponse = Awaited<ReturnType<typeof AssetsService.findOneAsset>>;
export type AssetsServiceFindOneAssetQueryResult<TData = AssetsServiceFindOneAssetDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useAssetsServiceFindOneAssetKey = "AssetsServiceFindOneAsset";
export const UseAssetsServiceFindOneAssetKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useAssetsServiceFindOneAssetKey, ...(queryKey ?? [{ id }])];
export type RoutesServiceFindManyRoutesDefaultResponse = Awaited<ReturnType<typeof RoutesService.findManyRoutes>>;
export type RoutesServiceFindManyRoutesQueryResult<TData = RoutesServiceFindManyRoutesDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRoutesServiceFindManyRoutesKey = "RoutesServiceFindManyRoutes";
export const UseRoutesServiceFindManyRoutesKeyFn = ({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: Array<unknown>) => [useRoutesServiceFindManyRoutesKey, ...(queryKey ?? [{ page, recordsPerPage }])];
export type RoutesServiceFindOneRouteDefaultResponse = Awaited<ReturnType<typeof RoutesService.findOneRoute>>;
export type RoutesServiceFindOneRouteQueryResult<TData = RoutesServiceFindOneRouteDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useRoutesServiceFindOneRouteKey = "RoutesServiceFindOneRoute";
export const UseRoutesServiceFindOneRouteKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useRoutesServiceFindOneRouteKey, ...(queryKey ?? [{ id }])];
export type MapsServiceFindManyMapsDefaultResponse = Awaited<ReturnType<typeof MapsService.findManyMaps>>;
export type MapsServiceFindManyMapsQueryResult<TData = MapsServiceFindManyMapsDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useMapsServiceFindManyMapsKey = "MapsServiceFindManyMaps";
export const UseMapsServiceFindManyMapsKeyFn = ({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: Array<unknown>) => [useMapsServiceFindManyMapsKey, ...(queryKey ?? [{ page, recordsPerPage }])];
export type MapsServiceFindOneMapDefaultResponse = Awaited<ReturnType<typeof MapsService.findOneMap>>;
export type MapsServiceFindOneMapQueryResult<TData = MapsServiceFindOneMapDefaultResponse, TError = unknown> = UseQueryResult<TData, TError>;
export const useMapsServiceFindOneMapKey = "MapsServiceFindOneMap";
export const UseMapsServiceFindOneMapKeyFn = ({ id }: {
  id: string;
}, queryKey?: Array<unknown>) => [useMapsServiceFindOneMapKey, ...(queryKey ?? [{ id }])];
export type AuthServiceLoginMutationResult = Awaited<ReturnType<typeof AuthService.login>>;
export type AuthServiceLogoutMutationResult = Awaited<ReturnType<typeof AuthService.logout>>;
export type UsersServiceCreateUserMutationResult = Awaited<ReturnType<typeof UsersService.createUser>>;
export type UsersServiceExportUsersMutationResult = Awaited<ReturnType<typeof UsersService.exportUsers>>;
export type SitesServiceCreateSiteMutationResult = Awaited<ReturnType<typeof SitesService.createSite>>;
export type SitesServiceExportSitesMutationResult = Awaited<ReturnType<typeof SitesService.exportSites>>;
export type AssetsServiceCreateAssetMutationResult = Awaited<ReturnType<typeof AssetsService.createAsset>>;
export type AssetsServiceExportAssetsMutationResult = Awaited<ReturnType<typeof AssetsService.exportAssets>>;
export type RoutesServiceCreateRouteMutationResult = Awaited<ReturnType<typeof RoutesService.createRoute>>;
export type RoutesServiceExportRoutesMutationResult = Awaited<ReturnType<typeof RoutesService.exportRoutes>>;
export type MapsServiceCreateMapMutationResult = Awaited<ReturnType<typeof MapsService.createMap>>;
export type MapsServiceExportMapsMutationResult = Awaited<ReturnType<typeof MapsService.exportMaps>>;
export type UsersServiceUpdateUserMutationResult = Awaited<ReturnType<typeof UsersService.updateUser>>;
export type SitesServiceUpdateSiteMutationResult = Awaited<ReturnType<typeof SitesService.updateSite>>;
export type AssetsServiceUpdateAssetMutationResult = Awaited<ReturnType<typeof AssetsService.updateAsset>>;
export type RoutesServiceUpdateRouteMutationResult = Awaited<ReturnType<typeof RoutesService.updateRoute>>;
export type MapsServiceUpdateMapMutationResult = Awaited<ReturnType<typeof MapsService.updateMap>>;
export type UsersServiceDeleteUserMutationResult = Awaited<ReturnType<typeof UsersService.deleteUser>>;
export type SitesServiceDeleteSiteMutationResult = Awaited<ReturnType<typeof SitesService.deleteSite>>;
export type AssetsServiceDeleteAssetMutationResult = Awaited<ReturnType<typeof AssetsService.deleteAsset>>;
export type RoutesServiceDeleteRouteMutationResult = Awaited<ReturnType<typeof RoutesService.deleteRoute>>;
export type MapsServiceDeleteMapMutationResult = Awaited<ReturnType<typeof MapsService.deleteMap>>;
