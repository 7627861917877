import {
  createFileRoute,
  ErrorComponentProps,
  Outlet,
  redirect,
  ErrorComponent as DefaultErrorComponent,
} from "@tanstack/react-router";
import { isAuthenticated } from "~/lib/auth";
import { useAuthServiceLogout, useUsersServiceFindMe } from "../api/queries";
import { notifications } from "@mantine/notifications";
import {
  Button,
  Container,
  Stack,
  Text,
  AppShell,
  Burger,
  ScrollArea,
  Flex,
  Center,
  Divider,
} from "@mantine/core";
import { useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  IconMap2,
  IconDirectionSign,
  IconMapPin,
  IconServer,
  IconUsers,
  IconMap,
  IconSettings,
} from "@tabler/icons-react";
import NavbarLink from "../components/navbar-link";
import LogoIcon from "../assets/logo-icon.svg";
import LogoIconDark from "../assets/logo-icon-dark.svg";
import ThemeToggle from "~/components/theme-toggle";
import LogoutButton from "~/components/logout-button";
import classes from "../components/layout.module.css";

const NAVBAR_WIDTH = 85;

export const Route = createFileRoute("/_auth")({
  beforeLoad: async () => {
    // Fast check to see if the user is authenticated using client readable auth cookie
    if (!isAuthenticated()) {
      throw redirect({
        to: "/sign-in",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }
  },
  component: AuthComponent,
  errorComponent: ErrorComponent,
});

function AuthComponent() {
  // Slower check to see if the user is actually authenticated and has valid session using the API
  useUsersServiceFindMe(undefined, {
    retry: false,
    throwOnError: true,
  });

  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();

  return (
    <AppShell
      navbar={{
        width: NAVBAR_WIDTH,
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened },
      }}
      padding="md"
    >
      <AppShell.Navbar p="md">
        <AppShell.Section>
          <Center>
            <img
              className={classes.light}
              src={LogoIcon}
              width={35}
              height={32}
              loading="lazy"
              alt="SiteRun logo"
            />
            <img
              className={classes.dark}
              src={LogoIconDark}
              width={35}
              height={32}
              loading="lazy"
              alt="SiteRun logo"
            />
          </Center>
        </AppShell.Section>

        <AppShell.Section grow my="md" component={ScrollArea}>
          <NavbarLink
            label="Main Map"
            icon={IconMap2}
            to="/maps/default"
            activeExact
          />

          <NavbarLink
            label="Route Planner"
            icon={IconDirectionSign}
            to="/maps/default/routes"
          />

          {/* <NavbarLink
            label="Issue Reporter"
            icon={IconBellPlus}
            to="/maps/default/issues"
          /> */}

          <Divider my="sm" />

          <NavbarLink label="Sites" icon={IconMapPin} to="/sites" />

          <NavbarLink label="Assets" icon={IconServer} to="/assets" />

          <NavbarLink label="Routes" icon={IconDirectionSign} to="/routes" />

          {/* <NavbarLink label="Issues" icon={IconBell} to="/issues" />

          <NavbarLink label="Templates" icon={IconTemplate} to="/templates" /> */}

          <NavbarLink label="Maps" icon={IconMap} to="/maps" activeExact />

          <NavbarLink label="Users" icon={IconUsers} to="/users" />
        </AppShell.Section>

        <AppShell.Section>
          <ThemeToggle />

          <NavbarLink label="Settings" icon={IconSettings} to="/settings" />

          <LogoutButton />
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main
        p={0}
        className={classes.shell}
        style={{ paddingInlineStart: 0 }}
      >
        <Flex pl={{ base: 0, xs: 0, sm: NAVBAR_WIDTH }}>
          <Burger
            style={{ position: "absolute", top: 15, right: 15, zIndex: 1000 }}
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="md"
          />

          <div style={{ width: "100%" }}>
            <Outlet />
          </div>
        </Flex>
      </AppShell.Main>
    </AppShell>
  );
}

function ErrorComponent(props: ErrorComponentProps) {
  const navigate = Route.useNavigate();
  const logout = useAuthServiceLogout({
    onSuccess: () => {
      navigate({ to: "/sign-in" });
    },
    onError: () => {
      notifications.show({
        title: "Error",
        message: "Something went wrong, please try again.",
        color: "red",
      });
    },
  });

  useEffect(() => {
    // Todo: Base redirection on error code not string message
    if (props.error.message === "Unauthorized") {
      logout.mutate();
    }
  }, [props.error.message]);

  if (import.meta.env.DEV) {
    return <DefaultErrorComponent {...props} />;
  }

  return (
    <Container>
      <Stack>
        <Text>Sorry we ran into an error: {props.error.message}</Text>

        <Button onClick={() => props.reset()}>Try again</Button>

        <Button
          variant="subtle"
          onClick={() => logout.mutate()}
          loading={logout.isPending}
        >
          Logout
        </Button>
      </Stack>
    </Container>
  );
}
