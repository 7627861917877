import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { Badge } from "@mantine/core";
import { paginationSchema } from "~/lib/pagination";
import { DataTableColumn } from "mantine-datatable";
import { ReadManyUserOutputDto } from "~/api/requests";
import ResourceList from "~/components/resource-list";
import {
  useUsersServiceDeleteUser,
  useUsersServiceExportUsers,
  useUsersServiceFindManyUsers,
} from "~/api/queries";

export const Route = createFileRoute("/_auth/users/")({
  component: UsersPage,
  validateSearch: paginationSchema,
  beforeLoad: () => ({
    title: "Users",
  }),
});

function UsersPage() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const columns = [
    {
      accessor: "email",
      sortable: true,
      render: ({ email }) => email,
    },
    {
      accessor: "firstName",
      sortable: true,
    },
    {
      accessor: "lastName",
      sortable: true,
    },

    {
      accessor: "phone",
    },
    {
      accessor: "address",
    },
    {
      accessor: "active",
      title: "Status",
      render: ({ active }) => (
        <Badge color={active ? "green" : "red"}>
          {active ? "Active" : "Inactive"}
        </Badge>
      ),
    },
    {
      accessor: "createdAt",
      sortable: true,
      render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
    },
  ] satisfies DataTableColumn<ReadManyUserOutputDto["records"][number]>[];

  return (
    <ResourceList
      title="Users"
      description="Accounts that have access to the application."
      createUrl="/users/create"
      editUrl={(record) => `/users/${record.id}`}
      columns={columns}
      page={page}
      recordsPerPage={recordsPerPage}
      onPageChange={(newPage) =>
        navigate({ search: (prev) => ({ ...prev, page: newPage }) })
      }
      paginationQueryFn={useUsersServiceFindManyUsers}
      exportMutationFn={useUsersServiceExportUsers}
      getExportVars={() => ({})}
      exportSuccessMessage="User list download will start shortly"
      deleteMutationFn={useUsersServiceDeleteUser}
      getDeleteVars={(record) => ({ id: record.id })}
      deleteSuccessMessage="User deleted"
    />
  );
}
