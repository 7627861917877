import { Container, Stack, Breadcrumbs, Title, Divider } from "@mantine/core";

type Props = {
  title: string;
  breadcrumbs?: React.ReactNode;
  children: React.ReactNode;
};

export default function FormPage({ title, breadcrumbs, children }: Props) {
  return (
    <Container my="xl" size="sm">
      <Stack>
        <Breadcrumbs>{breadcrumbs}</Breadcrumbs>

        <Title order={1}>{title}</Title>

        <Divider />

        {children}
      </Stack>
    </Container>
  );
}
