import {
  ActionIcon,
  Combobox,
  Loader,
  TextInput,
  useCombobox,
  Text,
  Modal,
  Stack,
  Button,
  Group,
  Alert,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useEffect, useState } from "react";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import { useUsersServiceSearchUsers } from "../api/queries";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import LocationSearch from "./location-search";

const userCreateSchema = z.object({
  id: z.string().nullable(),
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  address: z.string(),
  addressLatitude: z.number(),
  addressLongitude: z.number(),
});

const emailSchema = z.string().email();

type Props = {
  label?: string;
  defaultQuery: string;
  onSelect: (value: {
    id: string | null;
    email: string;
    firstName: string;
    lastName: string;
    address: string;
    addressLatitude: number;
    addressLongitude: number;
  }) => void;
};

/**
 * Component to search for users.
 */
export default function UserSearch({ label, defaultQuery, onSelect }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [query, setQuery] = useState<string>(defaultQuery);
  const [emailQuery, setEmailQuery] = useState<string>("");
  const [active, setActive] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const form = useForm<z.infer<typeof userCreateSchema>>({
    resolver: zodResolver(userCreateSchema),
    defaultValues: {
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      addressLatitude: 0,
      addressLongitude: 0,
    },
  });

  const userId = form.watch("id");
  const isExistingUser = userId !== null;

  const handleModalFormSubmit = form.handleSubmit((data) => {
    onSelect({
      id: data.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      address: data.address,
      addressLatitude: data.addressLatitude,
      addressLongitude: data.addressLongitude,
    });
    setQuery(`${data.firstName} ${data.lastName}`);
    close();
  });

  const userSearchResults = useUsersServiceSearchUsers(
    { firstName: query },
    undefined,
    { enabled: active },
  );

  const userEmailSearchResults = useUsersServiceSearchUsers(
    {
      email: emailQuery,
    },
    undefined,
    { enabled: emailSchema.safeParse(emailQuery).success },
  );

  useEffect(() => {
    const existingUser = userEmailSearchResults.data?.find(
      (item) => item.email === emailQuery,
    );

    if (existingUser) {
      form.setValue("id", existingUser.id);
      form.setValue("firstName", existingUser.firstName);
      form.setValue("lastName", existingUser.lastName);
      form.setValue("address", existingUser.address);
      form.setValue("addressLatitude", existingUser.addressLatitude);
      form.setValue("addressLongitude", existingUser.addressLongitude);
      showNotification({
        title: "Found existing user",
        message: `${existingUser.firstName} ${existingUser.lastName} has the email ${existingUser.email}. You can assign the route to them.`,
      });
    } else {
      form.setValue("id", null);
      form.setValue("firstName", "");
      form.setValue("lastName", "");
      form.setValue("address", "");
      form.setValue("addressLatitude", 0);
      form.setValue("addressLongitude", 0);
    }
  }, [userEmailSearchResults.data]);

  const options = (userSearchResults.data ?? []).map((item) => (
    <Combobox.Option value={item.id} key={item.id}>
      <div className="flex">
        <Text component="span" fz="sm" fw={500}>
          {item.firstName} {item.lastName}
        </Text>
        <Text component="span" fz="xs" opacity={0.6}>
          {" "}
          • {item.email}
        </Text>
      </div>
      <Text fz="xs" opacity={0.6}>
        {item.address}
      </Text>
    </Combobox.Option>
  ));

  return (
    <>
      <Combobox
        onOptionSubmit={(optionValue) => {
          setActive(false);

          if (optionValue === "$create") {
            open();
            combobox.closeDropdown();
            return;
          }

          const selectedOption = userSearchResults.data?.find(
            (item) => item.id === optionValue,
          );

          if (selectedOption) {
            const name = `${selectedOption.firstName} ${selectedOption.lastName}`;
            onSelect({
              id: selectedOption.id,
              email: selectedOption.email,
              firstName: selectedOption.firstName,
              lastName: selectedOption.lastName,
              address: selectedOption.address,
              addressLatitude: selectedOption.addressLatitude,
              addressLongitude: selectedOption.addressLongitude,
            });
            setQuery(name);
            combobox.closeDropdown();
          }
        }}
        store={combobox}
      >
        <Combobox.Target>
          <TextInput
            label={label}
            placeholder="Search or create..."
            value={query}
            onChange={(event) => {
              setActive(true);
              setQuery(event.currentTarget.value);
              combobox.resetSelectedOption();
              combobox.openDropdown();
            }}
            onClick={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
            rightSection={
              userSearchResults.isLoading ? (
                <Loader size={18} />
              ) : (
                <ActionIcon
                  onClick={() => {
                    setQuery("");
                  }}
                  variant="subtle"
                >
                  <IconX />
                </ActionIcon>
              )
            }
          />
        </Combobox.Target>

        <Combobox.Dropdown>
          <Combobox.Options>
            {options}

            {userSearchResults.isSuccess && options.length === 0 && (
              <Combobox.Empty>No results found</Combobox.Empty>
            )}

            <Combobox.Option value="$create">+ Create assignee</Combobox.Option>
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>

      <Modal opened={opened} onClose={close} title="Create Assignee">
        <form
          onSubmit={(event) => {
            // Since this is a nested form we should prevent propagation
            // So the parent form doesn't get submitted as well
            event.stopPropagation();

            handleModalFormSubmit(event);
          }}
          id="user"
        >
          <Stack>
            <Alert icon={<IconInfoCircle />}>
              Create a new user to be assigned to this route.
            </Alert>

            <TextInput
              label="Email"
              placeholder="name@example.com"
              {...form.register("email")}
              error={form.formState.errors.email?.message}
              onChange={(event) => {
                setEmailQuery(event.currentTarget.value);
              }}
              rightSection={
                userEmailSearchResults.isLoading ? <Loader size={18} /> : null
              }
              data-autofocus
            />

            <Group grow>
              <TextInput
                label="First name"
                {...form.register("firstName")}
                error={form.formState.errors.firstName?.message}
                disabled={isExistingUser}
              />

              <TextInput
                label="Last name"
                {...form.register("lastName")}
                error={form.formState.errors.lastName?.message}
                disabled={isExistingUser}
              />
            </Group>

            <LocationSearch
              disabled={isExistingUser}
              label="Address"
              includeSites={false}
              onSelect={(addressResult) => {
                form.setValue("address", addressResult.label);
                form.setValue("addressLatitude", addressResult.latitude);
                form.setValue("addressLongitude", addressResult.longitude);
              }}
              defaultQuery={form.watch("address")}
              onRemove={() => {
                form.setValue("address", "");
                form.setValue("addressLatitude", 0);
                form.setValue("addressLongitude", 0);
              }}
              canRemove
            />

            <Button type="submit" form="user">
              {isExistingUser ? "Assign user" : "Create"}
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
}
