import { createFileRoute, Link } from "@tanstack/react-router";
import dayjs from "dayjs";
import { paginationSchema } from "~/lib/pagination";
import { formatDuration, formatKms } from "~/lib/format";
import { DataTableColumn } from "mantine-datatable";
import { ReadManyRouteOutputDto } from "~/api/requests";
import ResourceList from "~/components/resource-list";
import { Button } from "@mantine/core";
import { IconMap2 } from "@tabler/icons-react";
import {
  useRoutesServiceDeleteRoute,
  useRoutesServiceExportRoutes,
  useRoutesServiceFindManyRoutes,
} from "~/api/queries";

export const Route = createFileRoute("/_auth/routes/")({
  component: RoutesPage,
  validateSearch: paginationSchema,
  beforeLoad: () => ({
    title: "Routes",
  }),
});

function RoutesPage() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const columns = [
    {
      accessor: "name",
      sortable: true,
      render: ({ name }) => name,
    },
    {
      accessor: "assignee.name",
      title: "Assignee",
      sortable: true,
    },
    {
      accessor: "stops",
      render: ({ loop, stops }) => (
        <>
          {stops.map((stop) => stop.label).join(", ")}
          {loop ? ", Back to start" : ""}
        </>
      ),
    },
    {
      accessor: "distance",
      render: ({ distance }) => formatKms(distance),
    },
    {
      accessor: "duration",
      render: ({ duration }) => formatDuration(duration),
    },
    {
      accessor: "createdAt",
      sortable: true,
      render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
    },
  ] satisfies DataTableColumn<ReadManyRouteOutputDto["records"][number]>[];

  return (
    <ResourceList
      title="Routes"
      description="Manage your site maintenance routes."
      createUrl="/maps/default/routes/new"
      editUrl={(record) => `/maps/default/routes/${record.id}`}
      columns={columns}
      page={page}
      recordsPerPage={recordsPerPage}
      onPageChange={(newPage) =>
        navigate({ search: (prev) => ({ ...prev, page: newPage }) })
      }
      paginationQueryFn={useRoutesServiceFindManyRoutes}
      exportMutationFn={useRoutesServiceExportRoutes}
      getExportVars={() => ({})}
      exportSuccessMessage="Route list download will start shortly"
      deleteMutationFn={useRoutesServiceDeleteRoute}
      getDeleteVars={(record) => ({ id: record.id })}
      deleteSuccessMessage="Route deleted"
      headerComponents={
        <Button
          variant="light"
          leftSection={<IconMap2 size={14} />}
          component={Link}
          to="/maps/default/routes"
        >
          Show on map
        </Button>
      }
    />
  );
}
