import {
  Container,
  Paper,
  Text,
  TextInput,
  Button,
  Stack,
  Group,
  Divider,
  Center,
  Alert,
} from "@mantine/core";
import { createFileRoute, useRouter } from "@tanstack/react-router";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuthServiceLogin } from "../api/queries";
import GoogleIcon from "../components/google-icon";
import MicrosoftIcon from "../components/microsoft-icon";
import Logo from "~/assets/logo.png";
import { IconAlertTriangle } from "@tabler/icons-react";

const emailPasswordSchema = z.object({
  email: z.string().email(),
  password: z.string().min(5),
});

export const Route = createFileRoute("/sign-in")({
  component: SignIn,
});

function SignIn() {
  const router = useRouter();
  const login = useAuthServiceLogin();

  const emailPasswordForm = useForm({
    resolver: zodResolver(emailPasswordSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = emailPasswordForm.handleSubmit(async (data) => {
    try {
      await login.mutateAsync({ requestBody: data });
      await router.navigate({
        to: "/maps/default",
      });
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Container size="xs" style={{ paddingTop: "20vh" }}>
      <Paper radius="md" p="xl" withBorder>
        <Center mb="sm">
          <img src={Logo} width={150} height={34} loading="lazy" />
        </Center>

        <Text size="md" fw={500} ta="center">
          Please sign in to continue
        </Text>

        <Group grow mb="md" mt="md">
          <Button
            leftSection={<GoogleIcon />}
            variant="default"
            component="a"
            href="/api/auth/providers/google"
          >
            Google
          </Button>
        </Group>

        <Group grow mb="md" mt="md">
          <Button
            leftSection={<MicrosoftIcon />}
            variant="default"
            component="a"
            href="/api/auth/providers/microsoft"
          >
            Microsoft
          </Button>
        </Group>

        <Divider
          label="Or continue with email"
          labelPosition="center"
          my="lg"
        />

        <form onSubmit={onSubmit}>
          <Stack>
            {login.isError && (
              <Alert
                variant="light"
                color="red"
                title="Something went wrong"
                icon={<IconAlertTriangle />}
              >
                {
                  // @ts-expect-error
                  login.error.message
                }
              </Alert>
            )}

            <TextInput
              label="Email"
              placeholder="Email"
              required
              type="email"
              {...emailPasswordForm.register("email")}
              error={emailPasswordForm.formState.errors.email?.message}
            />

            <TextInput
              label="Password"
              placeholder="Password"
              required
              type="password"
              {...emailPasswordForm.register("password")}
              error={emailPasswordForm.formState.errors.password?.message}
            />

            <Button
              type="submit"
              loading={emailPasswordForm.formState.isSubmitting}
            >
              Sign in
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
