import { createFileRoute } from "@tanstack/react-router";
import {
  ActionIcon,
  Badge,
  CopyButton,
  Group,
  rem,
  Text,
  Tooltip,
} from "@mantine/core";
import dayjs from "dayjs";
import { DataTableColumn } from "mantine-datatable";
import {
  useAssetsServiceDeleteAsset,
  useAssetsServiceExportAssets,
  useAssetsServiceFindManyAssets,
} from "~/api/queries";
import { ReadManyAssetOutputDto } from "~/api/requests";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import ResourceList from "~/components/resource-list";
import { paginationSchema } from "@siterun/schema";

export const Route = createFileRoute("/_auth/assets/")({
  component: AssetsPage,
  validateSearch: paginationSchema,
  beforeLoad: () => ({
    title: "Assets",
  }),
});

function AssetsPage() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const columns = [
    {
      accessor: "name",
      sortable: true,
      render: () => "Example",
    },
    {
      accessor: "siteId",
      title: "Site",
      sortable: true,
    },
    {
      accessor: "ip",
      title: "IP Address",
      sortable: true,
      render: () => (
        <Group gap={5}>
          <Text size="sm">255.255.255.255</Text>
          <CopyButton value="https://mantine.dev" timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? "Copied" : "Copy"}
                withArrow
                position="right"
              >
                <ActionIcon
                  color={copied ? "teal" : "gray"}
                  variant="subtle"
                  onClick={copy}
                >
                  {copied ? (
                    <IconCheck style={{ width: rem(16) }} />
                  ) : (
                    <IconCopy style={{ width: rem(16) }} />
                  )}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Group>
      ),
    },
    {
      accessor: "active",
      title: "Status",
      sortable: true,
      render: () => <Badge color="green">Active</Badge>,
    },
    {
      accessor: "createdAt",
      sortable: true,
      render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
    },
  ] as DataTableColumn<ReadManyAssetOutputDto["records"][number]>[];

  return (
    <ResourceList
      title="Assets"
      description="Physical resources that belong to a site, for example cameras, servers, or power systems."
      createUrl="/assets/create"
      editUrl={(record) => `/assets/${record.id}`}
      columns={columns}
      page={page}
      recordsPerPage={recordsPerPage}
      onPageChange={(newPage) =>
        navigate({ search: (prev) => ({ ...prev, page: newPage }) })
      }
      paginationQueryFn={useAssetsServiceFindManyAssets}
      exportMutationFn={useAssetsServiceExportAssets}
      getExportVars={() => ({})}
      exportSuccessMessage="Asset list download will start shortly"
      deleteMutationFn={useAssetsServiceDeleteAsset}
      getDeleteVars={(record) => ({ id: record.id })}
      deleteSuccessMessage="Asset deleted"
    />
  );
}
