import { createFileRoute, Link } from "@tanstack/react-router";
import { Anchor } from "@mantine/core";
import { queryClient } from "~/app";
import {
  useMapsServiceCreateMap,
  useMapsServiceFindManyMapsKey,
} from "~/api/queries";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import FormPage from "~/components/layouts/form-page";
import MapForm from "~/components/forms/map";

const TITLE = "Create Map";

export const Route = createFileRoute("/_auth/maps/create")({
  component: MapPage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
});

function MapPage() {
  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/maps">
          Maps
        </Anchor>,
        <Anchor component={Link} to={`/maps/create`}>
          {TITLE}
        </Anchor>,
      ]}
    >
      <MapForm
        defaultValues={{
          name: "",
          styleLight: "",
          styleDark: "",
        }}
        mutationFn={useMapsServiceCreateMap}
        getVars={(requestBody) => ({ requestBody })}
        successMessage="Created map"
        invalidateQueries={() =>
          queryClient.invalidateQueries({
            queryKey: [useMapsServiceFindManyMapsKey],
          })
        }
        cancelUrl="/maps"
      />
    </FormPage>
  );
}
