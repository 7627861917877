// generated with @7nohe/openapi-react-query-codegen@1.4.1 

import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { AssetsService, AuthService, MapsService, RoutesService, SitesService, UsersService } from "../requests/services.gen";
import { CreateAssetInputDto, CreateMapInputDto, CreateRouteInputDto, CreateSiteInputDto, CreateUserInputDto, LoginInputDto, UpdateAssetInputDto, UpdateMapInputDto, UpdateRouteInputDto, UpdateSiteInputDto, UpdateUserInputDto } from "../requests/types.gen";
import * as Common from "./common";
export const useAuthServiceGoogle = <TData = Common.AuthServiceGoogleDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAuthServiceGoogleKeyFn(queryKey), queryFn: () => AuthService.google() as TData, ...options });
export const useAuthServiceAuthControllerGoogleCallback = <TData = Common.AuthServiceAuthControllerGoogleCallbackDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAuthServiceAuthControllerGoogleCallbackKeyFn(queryKey), queryFn: () => AuthService.authControllerGoogleCallback() as TData, ...options });
export const useAuthServiceMicrosoft = <TData = Common.AuthServiceMicrosoftDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAuthServiceMicrosoftKeyFn(queryKey), queryFn: () => AuthService.microsoft() as TData, ...options });
export const useAuthServiceAuthControllerMicrosoftCallback = <TData = Common.AuthServiceAuthControllerMicrosoftCallbackDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAuthServiceAuthControllerMicrosoftCallbackKeyFn(queryKey), queryFn: () => AuthService.authControllerMicrosoftCallback() as TData, ...options });
export const useUsersServiceFindManyUsers = <TData = Common.UsersServiceFindManyUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceFindManyUsersKeyFn({ page, recordsPerPage }, queryKey), queryFn: () => UsersService.findManyUsers({ page, recordsPerPage }) as TData, ...options });
export const useUsersServiceSearchUsers = <TData = Common.UsersServiceSearchUsersDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ email, firstName, lastName }: {
  email?: string;
  firstName?: string;
  lastName?: string;
} = {}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceSearchUsersKeyFn({ email, firstName, lastName }, queryKey), queryFn: () => UsersService.searchUsers({ email, firstName, lastName }) as TData, ...options });
export const useUsersServiceFindMe = <TData = Common.UsersServiceFindMeDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceFindMeKeyFn(queryKey), queryFn: () => UsersService.findMe() as TData, ...options });
export const useUsersServiceFindOneUser = <TData = Common.UsersServiceFindOneUserDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseUsersServiceFindOneUserKeyFn({ id }, queryKey), queryFn: () => UsersService.findOneUser({ id }) as TData, ...options });
export const useSitesServiceFindManySites = <TData = Common.SitesServiceFindManySitesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSitesServiceFindManySitesKeyFn({ page, recordsPerPage }, queryKey), queryFn: () => SitesService.findManySites({ page, recordsPerPage }) as TData, ...options });
export const useSitesServiceFindManySitesGeojson = <TData = Common.SitesServiceFindManySitesGeojsonDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>(queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSitesServiceFindManySitesGeojsonKeyFn(queryKey), queryFn: () => SitesService.findManySitesGeojson() as TData, ...options });
export const useSitesServiceSearchSites = <TData = Common.SitesServiceSearchSitesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ code }: {
  code: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSitesServiceSearchSitesKeyFn({ code }, queryKey), queryFn: () => SitesService.searchSites({ code }) as TData, ...options });
export const useSitesServiceFindOneSite = <TData = Common.SitesServiceFindOneSiteDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseSitesServiceFindOneSiteKeyFn({ id }, queryKey), queryFn: () => SitesService.findOneSite({ id }) as TData, ...options });
export const useAssetsServiceFindManyAssets = <TData = Common.AssetsServiceFindManyAssetsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAssetsServiceFindManyAssetsKeyFn({ page, recordsPerPage }, queryKey), queryFn: () => AssetsService.findManyAssets({ page, recordsPerPage }) as TData, ...options });
export const useAssetsServiceSearchAssets = <TData = Common.AssetsServiceSearchAssetsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ name }: {
  name: unknown;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAssetsServiceSearchAssetsKeyFn({ name }, queryKey), queryFn: () => AssetsService.searchAssets({ name }) as TData, ...options });
export const useAssetsServiceFindOneAsset = <TData = Common.AssetsServiceFindOneAssetDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseAssetsServiceFindOneAssetKeyFn({ id }, queryKey), queryFn: () => AssetsService.findOneAsset({ id }) as TData, ...options });
export const useRoutesServiceFindManyRoutes = <TData = Common.RoutesServiceFindManyRoutesDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRoutesServiceFindManyRoutesKeyFn({ page, recordsPerPage }, queryKey), queryFn: () => RoutesService.findManyRoutes({ page, recordsPerPage }) as TData, ...options });
export const useRoutesServiceFindOneRoute = <TData = Common.RoutesServiceFindOneRouteDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseRoutesServiceFindOneRouteKeyFn({ id }, queryKey), queryFn: () => RoutesService.findOneRoute({ id }) as TData, ...options });
export const useMapsServiceFindManyMaps = <TData = Common.MapsServiceFindManyMapsDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ page, recordsPerPage }: {
  page: number;
  recordsPerPage: number;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseMapsServiceFindManyMapsKeyFn({ page, recordsPerPage }, queryKey), queryFn: () => MapsService.findManyMaps({ page, recordsPerPage }) as TData, ...options });
export const useMapsServiceFindOneMap = <TData = Common.MapsServiceFindOneMapDefaultResponse, TError = unknown, TQueryKey extends Array<unknown> = unknown[]>({ id }: {
  id: string;
}, queryKey?: TQueryKey, options?: Omit<UseQueryOptions<TData, TError>, "queryKey" | "queryFn">) => useQuery<TData, TError>({ queryKey: Common.UseMapsServiceFindOneMapKeyFn({ id }, queryKey), queryFn: () => MapsService.findOneMap({ id }) as TData, ...options });
export const useAuthServiceLogin = <TData = Common.AuthServiceLoginMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: LoginInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: LoginInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => AuthService.login({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAuthServiceLogout = <TData = Common.AuthServiceLogoutMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => AuthService.logout() as unknown as Promise<TData>, ...options });
export const useUsersServiceCreateUser = <TData = Common.UsersServiceCreateUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateUserInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateUserInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => UsersService.createUser({ requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceExportUsers = <TData = Common.UsersServiceExportUsersMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => UsersService.exportUsers() as unknown as Promise<TData>, ...options });
export const useSitesServiceCreateSite = <TData = Common.SitesServiceCreateSiteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateSiteInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateSiteInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => SitesService.createSite({ requestBody }) as unknown as Promise<TData>, ...options });
export const useSitesServiceExportSites = <TData = Common.SitesServiceExportSitesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => SitesService.exportSites() as unknown as Promise<TData>, ...options });
export const useAssetsServiceCreateAsset = <TData = Common.AssetsServiceCreateAssetMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateAssetInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateAssetInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => AssetsService.createAsset({ requestBody }) as unknown as Promise<TData>, ...options });
export const useAssetsServiceExportAssets = <TData = Common.AssetsServiceExportAssetsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => AssetsService.exportAssets() as unknown as Promise<TData>, ...options });
export const useRoutesServiceCreateRoute = <TData = Common.RoutesServiceCreateRouteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateRouteInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateRouteInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => RoutesService.createRoute({ requestBody }) as unknown as Promise<TData>, ...options });
export const useRoutesServiceExportRoutes = <TData = Common.RoutesServiceExportRoutesMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => RoutesService.exportRoutes() as unknown as Promise<TData>, ...options });
export const useMapsServiceCreateMap = <TData = Common.MapsServiceCreateMapMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  requestBody: CreateMapInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  requestBody: CreateMapInputDto;
}, TContext>({ mutationFn: ({ requestBody }) => MapsService.createMap({ requestBody }) as unknown as Promise<TData>, ...options });
export const useMapsServiceExportMaps = <TData = Common.MapsServiceExportMapsMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, void, TContext>, "mutationFn">) => useMutation<TData, TError, void, TContext>({ mutationFn: () => MapsService.exportMaps() as unknown as Promise<TData>, ...options });
export const useUsersServiceUpdateUser = <TData = Common.UsersServiceUpdateUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateUserInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateUserInputDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => UsersService.updateUser({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useSitesServiceUpdateSite = <TData = Common.SitesServiceUpdateSiteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateSiteInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateSiteInputDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => SitesService.updateSite({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useAssetsServiceUpdateAsset = <TData = Common.AssetsServiceUpdateAssetMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateAssetInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateAssetInputDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => AssetsService.updateAsset({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useRoutesServiceUpdateRoute = <TData = Common.RoutesServiceUpdateRouteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateRouteInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateRouteInputDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => RoutesService.updateRoute({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useMapsServiceUpdateMap = <TData = Common.MapsServiceUpdateMapMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
  requestBody: UpdateMapInputDto;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
  requestBody: UpdateMapInputDto;
}, TContext>({ mutationFn: ({ id, requestBody }) => MapsService.updateMap({ id, requestBody }) as unknown as Promise<TData>, ...options });
export const useUsersServiceDeleteUser = <TData = Common.UsersServiceDeleteUserMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => UsersService.deleteUser({ id }) as unknown as Promise<TData>, ...options });
export const useSitesServiceDeleteSite = <TData = Common.SitesServiceDeleteSiteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => SitesService.deleteSite({ id }) as unknown as Promise<TData>, ...options });
export const useAssetsServiceDeleteAsset = <TData = Common.AssetsServiceDeleteAssetMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => AssetsService.deleteAsset({ id }) as unknown as Promise<TData>, ...options });
export const useRoutesServiceDeleteRoute = <TData = Common.RoutesServiceDeleteRouteMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => RoutesService.deleteRoute({ id }) as unknown as Promise<TData>, ...options });
export const useMapsServiceDeleteMap = <TData = Common.MapsServiceDeleteMapMutationResult, TError = unknown, TContext = unknown>(options?: Omit<UseMutationOptions<TData, TError, {
  id: string;
}, TContext>, "mutationFn">) => useMutation<TData, TError, {
  id: string;
}, TContext>({ mutationFn: ({ id }) => MapsService.deleteMap({ id }) as unknown as Promise<TData>, ...options });
