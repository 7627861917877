import { createFileRoute, Link } from "@tanstack/react-router";
import { queryClient } from "~/app";
import {
  useSitesServiceCreateSite,
  useSitesServiceFindManySitesKey,
} from "~/api/queries";
import SiteForm from "~/components/forms/site";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import { Anchor } from "@mantine/core";
import FormPage from "~/components/layouts/form-page";

const TITLE = "Create Site";

export const Route = createFileRoute("/_auth/sites/create")({
  component: CreateSitePage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
});

function CreateSitePage() {
  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/sites">
          Sites
        </Anchor>,
        <Anchor component={Link} to="/sites/create">
          {TITLE}
        </Anchor>,
      ]}
    >
      <SiteForm
        defaultValues={{
          name: "",
          code: "",
          latitude: 0,
          longitude: 0,
        }}
        mutationFn={useSitesServiceCreateSite}
        getVars={(requestBody) => ({ requestBody })}
        successMessage="Created site"
        invalidateQueries={() =>
          queryClient.invalidateQueries({
            queryKey: [useSitesServiceFindManySitesKey],
          })
        }
        cancelUrl="/sites"
      />
    </FormPage>
  );
}
