import { createFileRoute, Link } from "@tanstack/react-router";
import { Anchor } from "@mantine/core";
import { queryClient } from "~/app";
import { AssetsService } from "~/api/requests";
import {
  useAssetsServiceFindOneAssetKey,
  useAssetsServiceUpdateAsset,
} from "~/api/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import AssetForm from "~/components/forms/asset";
import FormPage from "~/components/layouts/form-page";

const TITLE = "Edit Asset";

const queryOptions = (id: string) => ({
  queryKey: [useAssetsServiceFindOneAssetKey, id],
  queryFn: () => AssetsService.findOneAsset({ id }),
});

export const Route = createFileRoute("/_auth/assets/$assetId")({
  component: AssetPage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
  loader: ({ params }) =>
    queryClient.ensureQueryData(queryOptions(params.assetId)),
});

function AssetPage() {
  const { assetId } = Route.useParams();

  const { data } = useSuspenseQuery(queryOptions(assetId));

  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/assets">
          Assets
        </Anchor>,
        <Anchor component={Link} to={`/assets/${assetId}`}>
          {data.name}
        </Anchor>,
      ]}
    >
      <AssetForm
        defaultValues={data}
        mutationFn={useAssetsServiceUpdateAsset}
        getVars={(requestBody) => ({ id: assetId, requestBody })}
        successMessage="Updated asset"
        invalidateQueries={() =>
          queryClient.invalidateQueries(queryOptions(assetId))
        }
        cancelUrl="/assets"
      />
    </FormPage>
  );
}
