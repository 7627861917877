import { createFileRoute, Link } from "@tanstack/react-router";
import { queryClient } from "~/app";
import { UsersService } from "~/api/requests";
import {
  useUsersServiceFindOneUserKey,
  useUsersServiceUpdateUser,
} from "~/api/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import UserForm from "~/components/forms/user";
import FormPage from "~/components/layouts/form-page";
import { Anchor } from "@mantine/core";

const queryOptions = (id: string) => ({
  queryKey: [useUsersServiceFindOneUserKey, id],
  queryFn: () => UsersService.findOneUser({ id }),
});

export const Route = createFileRoute("/_auth/users/$userId")({
  component: UserPage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: "Edit User",
  }),
  loader: ({ params }) =>
    queryClient.ensureQueryData(queryOptions(params.userId)),
});

function UserPage() {
  const { userId } = Route.useParams();

  const { data } = useSuspenseQuery(queryOptions(userId));

  return (
    <FormPage
      title="Update User"
      breadcrumbs={[
        <Anchor component={Link} to="/users">
          Users
        </Anchor>,
        <Anchor component={Link} to="/users/$userId" params={{ userId }}>
          {data.firstName} {data.lastName}
        </Anchor>,
      ]}
    >
      <UserForm
        defaultValues={data}
        mutationFn={useUsersServiceUpdateUser}
        getVars={(requestBody) => ({ id: userId, requestBody })}
        successMessage="Updated user"
        invalidateQueries={() =>
          queryClient.invalidateQueries(queryOptions(userId))
        }
        cancelUrl="/users"
      />
    </FormPage>
  );
}
