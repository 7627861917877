import { createFileRoute, Link } from "@tanstack/react-router";
import { Anchor } from "@mantine/core";
import { queryClient } from "~/app";
import { MapsService } from "~/api/requests";
import {
  useMapsServiceFindOneMapKey,
  useMapsServiceUpdateMap,
} from "~/api/queries";
import { useSuspenseQuery } from "@tanstack/react-query";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import FormPage from "~/components/layouts/form-page";
import MapForm from "~/components/forms/map";

const TITLE = "Edit Map";

const queryOptions = (id: string) => ({
  queryKey: [useMapsServiceFindOneMapKey, id],
  queryFn: () => MapsService.findOneMap({ id }),
});

export const Route = createFileRoute("/_auth/maps/$mapId")({
  component: MapPage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
  loader: ({ params }) =>
    queryClient.ensureQueryData(queryOptions(params.mapId)),
});

function MapPage() {
  const { mapId } = Route.useParams();

  const { data } = useSuspenseQuery(queryOptions(mapId));

  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/maps">
          Maps
        </Anchor>,
        <Anchor component={Link} to={`/maps/${mapId}`}>
          {data.name}
        </Anchor>,
      ]}
    >
      <MapForm
        defaultValues={data}
        mutationFn={useMapsServiceUpdateMap}
        getVars={(requestBody) => ({ id: mapId, requestBody })}
        successMessage="Updated map"
        invalidateQueries={() =>
          queryClient.invalidateQueries(queryOptions(mapId))
        }
        cancelUrl="/maps"
      />
    </FormPage>
  );
}
