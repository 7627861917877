import { Link } from "@tanstack/react-router";
import { Button, Divider, Group, Stack } from "@mantine/core";
import {
  DefaultValues,
  FieldValues,
  useForm,
  UseFormReturn,
  FormProvider,
  useController,
  Path,
  UseFormRegisterReturn,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { showNotification } from "@mantine/notifications";

// T is a generic type that represents the form data (ie default values).
// TData is a generic type that represents the data that the mutation will return.
// TVars is a generic type that represents the variables that the mutation will accept.
type Props<T extends FieldValues, TData, TVars> = {
  // Zod validation schema
  schema: z.ZodType<T, any>;
  // Form default values
  defaultValues: T;
  // Get variables for the mutationFn
  getVars: (values: T) => TVars;
  // React Query mutation function
  mutationFn: <TError = unknown, TContext = unknown>(
    options?: Omit<
      UseMutationOptions<TData, TError, TVars, TContext>,
      "mutationFn"
    >,
  ) => UseMutationResult<TData, TError, TVars, TContext>;
  // Form elements
  children: (
    form: Omit<UseFormReturn<T>, "register"> & {
      register: <Name extends Path<T>>(
        name: Name,
      ) => UseFormRegisterReturn<Path<T>> & { error: string | undefined };
    },
  ) => React.ReactNode;
  successMessage: string;
  errorMessage?: string;
  // Invalidate queries [cache] after mutation
  invalidateQueries: () => void;
  cancelUrl: string;
};

export type FormProps<T extends FieldValues, TData, TVars> = Omit<
  Props<T, TData, TVars>,
  "schema" | "children"
>;

export default function Form<T extends FieldValues, TData, TVars>({
  schema,
  defaultValues,
  children,
  mutationFn,
  getVars,
  successMessage,
  errorMessage = "Please try again later",
  invalidateQueries,
  cancelUrl,
}: Props<T, TData, TVars>) {
  const form = useForm<T>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues as DefaultValues<T>,
  });

  const { mutateAsync } = mutationFn({
    onSuccess: () => {
      showNotification({
        title: "Success",
        message: successMessage,
        color: "green",
      });
      invalidateQueries();
    },
    onError: () => {
      showNotification({
        title: "Something went wrong",
        message: errorMessage,
        color: "red",
      });
    },
  });

  const handleSubmit = async (values: T) => {
    await mutateAsync(getVars(values));
  };

  const register = (name: Path<T>) => {
    const field = form.register<Path<T>>(name);
    const { fieldState } = useController<T, Path<T>>({
      name,
      control: form.control,
    });

    return { ...field, error: fieldState.error?.message };
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Stack>
          {children({ ...form, register })}

          <Divider />

          <Group justify="end">
            <Button variant="light" component={Link} to={cancelUrl}>
              Cancel
            </Button>

            <Button type="submit" loading={form.formState.isSubmitting}>
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </FormProvider>
  );
}
