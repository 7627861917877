import {
  createFileRoute,
  useNavigate,
  Outlet,
  ErrorComponent,
} from "@tanstack/react-router";

import {
  Map,
  Source,
  Layer,
  FullscreenControl,
  GeolocateControl,
  NavigationControl,
  ScaleControl,
  MapLayerMouseEvent,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import GeocoderControl from "../../components/geocoder";
import { useCallback, useState } from "react";
import { MAPBOX_PUBLIC_KEY } from "../../lib/constants";
import {
  useMapsServiceFindOneMapKey,
  useSitesServiceFindManySitesGeojson,
} from "~/api/queries";
import { z } from "zod";
import { useMantineColorScheme, useMatches } from "@mantine/core";
import HomeControl from "../../components/map-home";
import { MapsService } from "~/api/requests";
import { queryClient } from "~/app";
import { useSuspenseQuery } from "@tanstack/react-query";

const DEFAULT_MAP_ID = "default";

const queryOptions = (id: string) => ({
  queryKey: [useMapsServiceFindOneMapKey, id],
  queryFn: () => MapsService.findOneMap({ id }),
});

export const Route = createFileRoute("/_auth/maps/default")({
  component: MapPage,
  errorComponent: ErrorComponent,
  validateSearch: z.object({
    siteId: z.string().uuid().optional(),
  }),
  beforeLoad: () => ({
    title: "Overview",
  }),
  loader: () => queryClient.ensureQueryData(queryOptions(DEFAULT_MAP_ID)),
});

function MapPage() {
  const [cursor, setCursor] = useState<string>("auto");

  const { data: map } = useSuspenseQuery(queryOptions(DEFAULT_MAP_ID));

  const { data: sites } = useSitesServiceFindManySitesGeojson();

  const navigate = useNavigate({ from: Route.fullPath });

  const colorScheme = useMantineColorScheme().colorScheme;

  const showFullScreen = useMatches({
    base: false,
    sm: true,
  });

  const handleMapClick = useCallback(({ features }: MapLayerMouseEvent) => {
    const siteId = features?.[0]?.properties?.id;

    if (siteId) {
      navigate({
        search: () => ({ siteId }),
      });
    }
  }, []);

  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor("auto"), []);

  return (
    <Map
      mapboxAccessToken={MAPBOX_PUBLIC_KEY}
      initialViewState={{
        // Bounding box for New Zealand
        bounds: [
          [165.954319, -47.436123],
          [179.229264, -33.217074],
        ],
      }}
      style={{ width: "100%", height: "100vh" }}
      mapStyle={colorScheme === "light" ? map.styleLight : map.styleDark}
      interactiveLayerIds={["point", "point-label"]}
      onClick={handleMapClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      cursor={cursor}
    >
      <GeocoderControl
        mapboxAccessToken={MAPBOX_PUBLIC_KEY}
        position="top-left"
        countries="nz"
      />

      {showFullScreen && <FullscreenControl position="top-right" />}

      <HomeControl position="top-right" />

      <NavigationControl position="bottom-right" />

      <GeolocateControl position="bottom-right" />

      <ScaleControl />

      <Source id="sites" type="geojson" data={sites}>
        <Layer
          id="point"
          type="circle"
          paint={{
            "circle-radius": 7,
            "circle-color": "green",
            "circle-stroke-color": "white",
            "circle-stroke-width": 2,
          }}
        />
        <Layer
          id="point-label"
          type="symbol"
          layout={{
            "text-field": "{code}",
            "text-offset": [0, 0.5],
            "text-anchor": "top",
          }}
          paint={{
            "text-color": "#000",
            "text-halo-color": "#fff",
            "text-halo-width": 1,
          }}
        />
      </Source>

      <Outlet />
    </Map>
  );
}
