import { Checkbox, TextInput } from "@mantine/core";
import { assetInputSchema } from "@siterun/schema";
import Form, { FormProps } from "./form";
import { z } from "zod";

type FormSchema = z.infer<typeof assetInputSchema>;

export default function AssetForm<TData, TVars>(
  props: FormProps<FormSchema, TData, TVars>,
) {
  return (
    <Form {...props} schema={assetInputSchema}>
      {({ register }) => (
        <>
          <TextInput maw={200} label="Name" {...register("name")} />

          <Checkbox label="Active" {...register("active")}>
            Active
          </Checkbox>
        </>
      )}
    </Form>
  );
}
