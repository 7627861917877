import { createFileRoute } from "@tanstack/react-router";
import RouteForm from "../../components/form";
import ErrorComponent from "~/components/error";
import {
  useRoutesServiceCreateRoute,
  useRoutesServiceFindOneRoute,
} from "../../api/queries";
import { useRoutesServiceUpdateRoute } from "../../api/queries";
import { Alert } from "@mantine/core";
import { notifications } from "@mantine/notifications";

export const Route = createFileRoute(
  "/_auth/maps/default/_modal/routes/$routeId",
)({
  component: MapRoute,
  errorComponent: ErrorComponent,
});

function MapRoute() {
  const navigate = Route.useNavigate();
  const { routeId } = Route.useParams();
  const existingRoute = useRoutesServiceFindOneRoute(
    { id: routeId },
    undefined,
    {
      enabled: routeId !== "new",
    },
  );

  const updateRoute = useRoutesServiceUpdateRoute({
    onError: () => {
      notifications.show({
        color: "red",
        title: "Route update failed",
        message:
          "Sorry, something went wrong when updating the route. Please try again",
      });
    },
    onSuccess: (data) => {
      notifications.show({ title: "Route updated", message: data.name });
    },
  });

  const createRoute = useRoutesServiceCreateRoute({
    onError: () => {
      notifications.show({
        color: "red",
        title: "Route creation failed",
        message:
          "Sorry, something went wrong when creating route. Please try again",
      });
    },
    onSuccess: (data) => {
      notifications.show({ title: "Route created", message: data.name });
      navigate({
        to: "/maps/default/routes/$routeId",
      });
    },
  });

  if (existingRoute.isLoading) {
    return <div>Loading...</div>;
  }

  if (existingRoute.isError) {
    return <Alert color="red">Something went wrong</Alert>;
  }

  if (existingRoute.isSuccess) {
    return (
      <RouteForm
        defaultValues={existingRoute.data}
        onSubmit={(data) =>
          updateRoute.mutateAsync({
            id: routeId,
            requestBody: data,
          })
        }
      />
    );
  }

  return (
    <RouteForm
      defaultValues={{
        id: "",
        name: "",
        distance: 0,
        duration: 0,
        returnDistance: 0,
        returnDuration: 0,
        loop: true,
        assignee: null,
        stops: [
          {
            id: "",
            siteId: null,
            label: "",
            longitude: 0,
            latitude: 0,
            distance: 0,
            duration: 0,
          },
        ],
      }}
      onSubmit={(data) =>
        createRoute.mutateAsync({
          requestBody: data,
        })
      }
    />
  );
}
