import { QueryClient } from "@tanstack/react-query";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";

const RouterDevtools = import.meta.env.DEV
  ? React.lazy(() =>
      // Lazy load in development
      import("@tanstack/router-devtools").then((res) => ({
        default: res.TanStackRouterDevtools,
      })),
    )
  : () => null; // Render nothing in production

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient;
  title: string;
}>()({
  component: RootComponent,
});

function RootComponent() {
  // const pageTitle = router.state.matches.reverse().find((d) => d.context.title)
  //   ?.context.title;

  return (
    <>
      <Outlet />
      <ReactQueryDevtools buttonPosition="bottom-right" />
      <RouterDevtools position="bottom-right" />
    </>
  );
}
