import { createFileRoute, Link } from "@tanstack/react-router";
import { queryClient } from "~/app";
import {
  useAssetsServiceCreateAsset,
  useAssetsServiceFindManyAssetsKey,
} from "~/api/queries";
import PendingComponent from "~/components/pending";
import ErrorComponent from "~/components/error";
import { Anchor } from "@mantine/core";
import FormPage from "~/components/layouts/form-page";
import AssetForm from "~/components/forms/asset";

const TITLE = "Create Asset";

export const Route = createFileRoute("/_auth/assets/create")({
  component: CreateAssetPage,
  pendingComponent: PendingComponent,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
});

function CreateAssetPage() {
  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/assets">
          Assets
        </Anchor>,
        <Anchor component={Link} to="/assets/create">
          {TITLE}
        </Anchor>,
      ]}
    >
      <AssetForm
        defaultValues={{
          name: "",
          siteId: "",
          ip: "",
          port: 0,
          password: "",
          active: true,
        }}
        mutationFn={useAssetsServiceCreateAsset}
        getVars={(requestBody) => ({ requestBody })}
        successMessage="Created asset"
        invalidateQueries={() =>
          queryClient.invalidateQueries({
            queryKey: [useAssetsServiceFindManyAssetsKey],
          })
        }
        cancelUrl="/assets"
      />
    </FormPage>
  );
}
