import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import { paginationSchema } from "~/lib/pagination";
import { DataTableColumn } from "mantine-datatable";
import { ReadManyMapOutputDto } from "~/api/requests";
import ResourceList from "~/components/resource-list";
import {
  useMapsServiceDeleteMap,
  useMapsServiceExportMaps,
  useMapsServiceFindManyMaps,
} from "~/api/queries";

export const Route = createFileRoute("/_auth/maps/")({
  component: MapsPage,
  validateSearch: paginationSchema,
  beforeLoad: () => ({
    title: "Maps",
  }),
});

function MapsPage() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const columns = [
    {
      accessor: "name",
      sortable: true,
      render: ({ name }) => name,
    },
    {
      accessor: "styleLight",
      title: "Light style",
      sortable: true,
    },
    {
      accessor: "styleDark",
      title: "Dark style",
      sortable: true,
    },
    {
      accessor: "createdAt",
      sortable: true,
      render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
    },
  ] satisfies DataTableColumn<ReadManyMapOutputDto["records"][number]>[];

  return (
    <ResourceList
      title="Maps"
      description="Manage map styles and filter settings."
      createUrl="/maps/create"
      editUrl={(record) => `/maps/${record.id}`}
      columns={columns}
      page={page}
      recordsPerPage={recordsPerPage}
      onPageChange={(newPage) =>
        navigate({ search: (prev) => ({ ...prev, page: newPage }) })
      }
      paginationQueryFn={useMapsServiceFindManyMaps}
      exportMutationFn={useMapsServiceExportMaps}
      getExportVars={() => ({})}
      exportSuccessMessage="Map list download will start shortly"
      deleteMutationFn={useMapsServiceDeleteMap}
      getDeleteVars={(record) => ({ id: record.id })}
      deleteSuccessMessage="Map deleted"
    />
  );
}
