import {
  Stack,
  Button,
  TextInput,
  Group,
  Timeline,
  Title,
  Text,
  ScrollArea,
  Checkbox,
} from "@mantine/core";
import { Link } from "@tanstack/react-router";
import { FeatureCollection, GeometryObject } from "geojson";
import { Source, Layer, useMap } from "react-map-gl";
import { Fragment } from "react/jsx-runtime";
import LocationSearch from "./location-search";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { calculateRoute } from "../lib/mapbox";
import { bbox } from "@turf/turf";
import { useEffect } from "react";
import { formatDuration, formatKms } from "../lib/format";
import UserSearch from "./user-search";
import { routeSchema, RouteSchema } from "@siterun/schema";

interface Props {
  defaultValues: RouteSchema;
  onSubmit: (data: RouteSchema) => Promise<RouteSchema>;
}

export default function RouteForm({ defaultValues, onSubmit }: Props) {
  const form = useForm<RouteSchema>({
    mode: "onSubmit",
    resolver: zodResolver(routeSchema),
    defaultValues,
  });

  const handleOnSubmit = form.handleSubmit(async (data) => {
    const result = await onSubmit(data);
    form.reset(result);
  });

  const watchId = form.watch("id");
  const watchAssignee = form.watch("assignee");
  const watchDistance = form.watch("distance");
  const watchDuration = form.watch("duration");
  const watchReturnDistance = form.watch("returnDistance");
  const watchReturnDuration = form.watch("returnDuration");
  const watchLoop = form.watch("loop");
  const watchStops = form.watch("stops");

  const stops = useFieldArray({
    name: "stops",
    control: form.control,
  });

  const directions = useQuery({
    queryKey: [
      "directions",
      watchStops.map((stop) => stop.siteId),
      watchLoop.toString(),
    ],
    queryFn: async () => {
      const locations = watchStops.map((stop) => ({
        name: stop.label,
        longitude: stop.longitude,
        latitude: stop.latitude,
      }));

      if (watchLoop) {
        locations.push(locations[0]);
      }

      return await calculateRoute(locations);
    },
    enabled: watchStops.length >= 2,
  });

  const map = useMap();

  // Fly to start of route and fit bounds to route as it is updated
  useEffect(() => {
    if (
      watchStops.length === 1 &&
      watchStops[0].latitude &&
      watchStops[0].longitude
    ) {
      map.current?.flyTo({
        center: [watchStops[0].longitude, watchStops[0].latitude],
        zoom: 14,
      });
    } else if (watchStops.length >= 2 && directions.data) {
      if (directions.data) {
        const [minLng, minLat, maxLng, maxLat] = bbox(
          directions.data.routes[0].geometry,
        );

        map.current?.fitBounds(
          [
            [minLng, minLat],
            [maxLng, maxLat],
          ],
          {
            padding: {
              left: 250,
              top: 100,
              right: 100,
              bottom: 100,
            },
          },
        );
      }
    }
  }, [directions.data, watchStops[0]]);

  // Update top level route and stops with distance and duration from Mapbox directions result
  useEffect(() => {
    const resultRoute = directions.data?.routes?.[0];

    if (resultRoute) {
      form.setValue("distance", resultRoute.distance);
      form.setValue("duration", resultRoute.duration);

      const maxIndex = watchStops.length - 1;

      resultRoute.legs.map((leg, index) => {
        // Skip first stop as it is the start of the route
        const updateIndex = index + 1;

        if (updateIndex <= maxIndex) {
          form.setValue(`stops.${updateIndex}.distance`, leg.distance);
          form.setValue(`stops.${updateIndex}.duration`, leg.duration);
        }
      });

      if (watchLoop && resultRoute.legs[resultRoute.legs.length - 1]) {
        form.setValue(
          "returnDistance",
          resultRoute.legs[resultRoute.legs.length - 1].distance,
        );
        form.setValue(
          "returnDuration",
          resultRoute.legs[resultRoute.legs.length - 1].duration,
        );
      }
    }
  }, [directions.data]);

  return (
    <Fragment>
      <Source
        id="routes"
        type="geojson"
        data={{
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: directions.data?.routes[0].geometry.coordinates,
          },
        }}
      >
        <Layer
          type="line"
          layout={{ "line-join": "round", "line-cap": "round" }}
          paint={{
            "line-color": "#3887be",
            "line-width": 5,
            "line-opacity": 0.75,
          }}
        />
      </Source>

      <Source
        id="route-sites"
        type="geojson"
        data={
          {
            type: "FeatureCollection",
            features: watchStops.map((stop) => ({
              type: "Feature",
              properties: { name: stop.label, siteId: stop.siteId },
              geometry: {
                type: "Point",
                coordinates: [stop.longitude, stop.latitude],
              },
            })),
          } satisfies FeatureCollection<GeometryObject>
        }
      >
        <Layer
          id="site-point"
          type="circle"
          paint={{
            "circle-radius": 7,
            "circle-color": "blue",
            "circle-stroke-color": "white",
            "circle-stroke-width": 2,
          }}
        />
        <Layer
          id="site-point-label"
          type="symbol"
          layout={{
            "text-field": "{code}",
            "text-offset": [0, 0.5],
            "text-anchor": "top",
          }}
          paint={{
            "text-color": "#000",
            "text-halo-color": "#fff",
            "text-halo-width": 1,
          }}
        />
      </Source>

      <Title order={1} mb={0} lh={1}>
        {watchId ? "Update" : "Create"} Route
      </Title>
      <Text mt="-10px" mb={2} c="dimmed">
        {watchStops.length + (watchLoop ? 1 : 0)} Stops ·{" "}
        {formatKms(watchDistance)} · {formatDuration(watchDuration)}
      </Text>

      <form onSubmit={handleOnSubmit} id="route">
        <Stack gap="md">
          <TextInput
            label="Name"
            {...form.register("name")}
            error={form.formState.errors.name?.message}
            data-autofocus
          />

          <UserSearch
            label="Assignee"
            defaultQuery={
              watchAssignee
                ? `${watchAssignee.firstName} ${watchAssignee.lastName}`
                : ""
            }
            onSelect={(selected) => {
              form.setValue("assignee", { ...selected });

              if (
                selected.address &&
                selected.addressLatitude &&
                selected.addressLongitude
              ) {
                form.setValue("stops.0", {
                  id: "",
                  siteId: null,
                  label: selected.address,
                  longitude: selected.addressLongitude,
                  latitude: selected.addressLatitude,
                  distance: 0,
                  duration: 0,
                });
              }
            }}
          />

          <Checkbox
            label="Loop"
            description="Return to the start after the last stop"
            {...form.register("loop")}
            error={form.formState.errors.loop?.message}
          />

          <ScrollArea.Autosize mah="calc(100vh - 380px)">
            <Timeline bulletSize={24} active={stops.fields.length}>
              {stops.fields.map((field, index) => (
                <Timeline.Item key={field.id} title={`Stop ${index + 1}`}>
                  <LocationSearch
                    description={`${formatKms(watchStops[index].distance)} · ${formatDuration(watchStops[index].duration)}`}
                    defaultQuery={watchStops[index].label}
                    onSelect={(selected) =>
                      form.setValue(`stops.${index}`, {
                        id: "",
                        siteId: selected.siteId,
                        longitude: selected.longitude,
                        latitude: selected.latitude,
                        label: selected.label,
                        distance: 0,
                        duration: 0,
                      })
                    }
                    canRemove={stops.fields.length > 1 || index > 0}
                    onRemove={() => stops.remove(index)}
                  />
                </Timeline.Item>
              ))}

              {watchLoop && (
                <Timeline.Item title={`Stop ${stops.fields.length + 1}`}>
                  <Text
                    size="xs"
                    c="dimmed"
                  >{`${formatKms(watchReturnDistance)} · ${formatDuration(watchReturnDuration)}`}</Text>
                  <Text>Return to start</Text>
                </Timeline.Item>
              )}
            </Timeline>
          </ScrollArea.Autosize>

          <Group style={{ paddingLeft: 42, marginTop: -5 }}>
            <Button
              fullWidth
              onClick={() =>
                stops.append({
                  id: "",
                  siteId: "",
                  label: "",
                  longitude: 0,
                  latitude: 0,
                  distance: 0,
                  duration: 0,
                })
              }
            >
              Add stop
            </Button>
          </Group>

          <Group justify="right">
            <Button variant="subtle" component={Link} to="/maps/default/routes">
              Back
            </Button>

            <Button
              type="submit"
              form="route"
              loading={form.formState.isLoading}
            >
              Save
            </Button>
          </Group>
        </Stack>
      </form>
    </Fragment>
  );
}
