import { createFileRoute } from "@tanstack/react-router";
import dayjs from "dayjs";
import {
  useSitesServiceDeleteSite,
  useSitesServiceExportSites,
  useSitesServiceFindManySites,
} from "~/api/queries";
import { paginationSchema } from "~/lib/pagination";
import { DataTableColumn } from "mantine-datatable";
import { ReadManySiteOutputDto } from "~/api/requests";
import ResourceList from "~/components/resource-list";

export const Route = createFileRoute("/_auth/sites/")({
  component: SitesPage,
  validateSearch: paginationSchema,
  beforeLoad: () => ({
    title: "View Sites",
  }),
});

function SitesPage() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const columns = [
    {
      accessor: "name",
      sortable: true,
      render: ({ name }) => name,
    },
    {
      accessor: "code",
      sortable: true,
    },
    {
      accessor: "createdAt",
      sortable: true,
      render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
    },
  ] satisfies DataTableColumn<ReadManySiteOutputDto["records"][number]>[];

  return (
    <ResourceList
      title="Sites"
      description="Manage your physical locations where assets are kept."
      createUrl="/sites/create"
      editUrl={(record) => `/sites/${record.id}`}
      columns={columns}
      page={page}
      recordsPerPage={recordsPerPage}
      onPageChange={(newPage) =>
        navigate({ search: (prev) => ({ ...prev, page: newPage }) })
      }
      paginationQueryFn={useSitesServiceFindManySites}
      exportMutationFn={useSitesServiceExportSites}
      getExportVars={() => ({})}
      exportSuccessMessage="Site list download will start shortly"
      deleteMutationFn={useSitesServiceDeleteSite}
      getDeleteVars={(record) => ({ id: record.id })}
      deleteSuccessMessage="Site deleted"
    />
  );
}
