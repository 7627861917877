import { createFileRoute, Link } from "@tanstack/react-router";
import { Fragment } from "react";
import { Button, Group, Title } from "@mantine/core";
import Table from "../../components/table";
import dayjs from "dayjs";
import { IconArrowRight, IconPlus } from "@tabler/icons-react";
import { useRoutesServiceFindManyRoutes } from "../../api/queries";
import { paginationSchema } from "@siterun/schema";

export const Route = createFileRoute("/_auth/maps/default/_modal/routes/")({
  component: Routes,
  validateSearch: paginationSchema,
});

function Routes() {
  const { page, recordsPerPage } = Route.useSearch();
  const navigate = Route.useNavigate();

  const routes = useRoutesServiceFindManyRoutes({
    page,
    recordsPerPage,
  });

  return (
    <Fragment>
      <Title>Routes</Title>

      <Group justify="center" grow>
        <Button
          leftSection={<IconPlus size={14} />}
          component={Link}
          to="/maps/default/routes/new"
        >
          Create route
        </Button>

        <Button
          variant="light"
          rightSection={<IconArrowRight size={14} />}
          component={Link}
          to="/routes"
        >
          Manage routes
        </Button>
      </Group>

      <Table
        data={routes.data?.records}
        page={page}
        recordsPerPage={recordsPerPage}
        onPageChange={() => {}}
        totalRecords={routes.data?.totalRecords}
        isFetching={routes.isFetching}
        withTableBorder={false}
        minHeight={200}
        maxHeight={200}
        columns={[
          {
            accessor: "name",
            sortable: true,
          },
          {
            accessor: "createdAt",
            sortable: true,
            render: ({ createdAt }) => dayjs(createdAt).format("MMM D, YYYY"),
          },
        ]}
        onRowClick={({ record }) =>
          navigate({
            to: "/maps/default/routes/$routeId",
            params: { routeId: record.id },
          })
        }
      />
    </Fragment>
  );
}
