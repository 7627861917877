import {
  Anchor,
  Breadcrumbs,
  Button,
  Container,
  Stack,
  Title,
} from "@mantine/core";
import {
  IconEdit,
  IconPhoto,
  IconServer,
  IconSettings,
} from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, Link, Outlet } from "@tanstack/react-router";
import { useSitesServiceFindOneSiteKey } from "~/api/queries";
import { SitesService } from "~/api/requests";
import { queryClient } from "~/app";

export const fetchSiteById = (id: string) => ({
  queryKey: [useSitesServiceFindOneSiteKey, id],
  queryFn: () => SitesService.findOneSite({ id }),
});

export const Route = createFileRoute("/_auth/sites/$siteId")({
  component: SiteTabs,
  loader: ({ params }) =>
    queryClient.ensureQueryData(fetchSiteById(params.siteId)),
});

function SiteTabs() {
  const { siteId } = Route.useParams();

  const { data } = useSuspenseQuery(fetchSiteById(siteId));

  return (
    <Container my="xl" size="md">
      <Stack>
        <Breadcrumbs>
          <Anchor component={Link} to="/sites">
            Sites
          </Anchor>
          <Anchor component={Link} to="/sites/$siteId" params={{ siteId }}>
            {data.code}
          </Anchor>
          {window.location.pathname.includes("assets") && (
            <Anchor
              component={Link}
              to="/sites/$siteId/assets"
              params={{ siteId }}
            >
              Assets
            </Anchor>
          )}
          {window.location.pathname.includes("photos") && (
            <Anchor
              component={Link}
              to="/sites/$siteId/photos"
              params={{ siteId }}
            >
              Photos
            </Anchor>
          )}
          {window.location.pathname.includes("settings") && (
            <Anchor
              component={Link}
              to="/sites/$siteId/settings"
              params={{ siteId }}
            >
              Settings
            </Anchor>
          )}
        </Breadcrumbs>

        <Title order={1}>Update Site</Title>

        <Button.Group>
          <Button
            leftSection={<IconEdit size={18} />}
            variant="default"
            component={Link}
            to="/sites/$siteId"
            params={{ siteId }}
            activeOptions={{
              exact: true,
            }}
            activeProps={{ style: { fontWeight: 700 } }}
          >
            General
          </Button>
          <Button
            leftSection={<IconServer size={18} />}
            variant="default"
            component={Link}
            to="/sites/$siteId/assets"
            params={{ siteId }}
            activeProps={{ style: { fontWeight: 700 } }}
          >
            Assets
          </Button>
          <Button
            leftSection={<IconPhoto size={18} />}
            variant="default"
            component={Link}
            to="/sites/$siteId/photos"
            params={{ siteId }}
            activeProps={{ style: { fontWeight: 700 } }}
          >
            Photos
          </Button>
          <Button
            leftSection={<IconSettings size={18} />}
            variant="default"
            component={Link}
            to="/sites/$siteId/settings"
            params={{ siteId }}
            activeProps={{ style: { fontWeight: 700 } }}
          >
            Settings
          </Button>
        </Button.Group>

        <Outlet />
      </Stack>
    </Container>
  );
}
