import { createFileRoute, Link } from "@tanstack/react-router";
import { queryClient } from "~/app";
import {
  useUsersServiceFindManyUsersKey,
  useUsersServiceCreateUser,
} from "~/api/queries";
import ErrorComponent from "~/components/error";
import UserForm from "~/components/forms/user";
import FormPage from "~/components/layouts/form-page";
import { Anchor } from "@mantine/core";

const TITLE = "Create User";

export const Route = createFileRoute("/_auth/users/create")({
  component: UserPage,
  errorComponent: ErrorComponent,
  beforeLoad: () => ({
    title: TITLE,
  }),
});

function UserPage() {
  return (
    <FormPage
      title={TITLE}
      breadcrumbs={[
        <Anchor component={Link} to="/users">
          Users
        </Anchor>,
        <Anchor component={Link} to="/users/create">
          {TITLE}
        </Anchor>,
      ]}
    >
      <UserForm
        defaultValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          phone: "",
          address: "",
          addressLatitude: 0,
          addressLongitude: 0,
          active: true,
        }}
        mutationFn={useUsersServiceCreateUser}
        getVars={(requestBody) => ({ requestBody })}
        successMessage="Created user"
        invalidateQueries={() =>
          queryClient.invalidateQueries({
            queryKey: [useUsersServiceFindManyUsersKey],
          })
        }
        cancelUrl="/users"
      />
    </FormPage>
  );
}
