import { Group, TextInput } from "@mantine/core";
import { siteInputSchema } from "@siterun/schema";
import Form, { FormProps } from "./form";
import { z } from "zod";

type FormSchema = z.infer<typeof siteInputSchema>;

export default function SiteForm<TData, TVars>(
  props: FormProps<FormSchema, TData, TVars>,
) {
  return (
    <Form {...props} schema={siteInputSchema}>
      {({ register }) => (
        <>
          <Group grow>
            <TextInput maw={200} label="Code" {...register("code")} />

            <TextInput maw={400} label="Name" {...register("name")} />
          </Group>

          <Group grow>
            <TextInput maw={300} label="Longitude" {...register("longitude")} />

            <TextInput maw={300} label="Latitude" {...register("latitude")} />
          </Group>
        </>
      )}
    </Form>
  );
}
